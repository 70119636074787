.App {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  margin-bottom: 10px;
}

.App-link {
  color: rgb(112, 76, 182);
}
